Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.labelTitleText = "DataEncryption";
exports.labelBodyText = "DataEncryption Body";
exports.loginApiEndPoint = "bx_block_login/logins";
exports.stringEncryptionApiEndpoint =
  "bx_block_dataencryption/dataencryption/encryptstring";
exports.stringDecryptionApiEndpoint =
  "bx_block_dataencryption/dataencryption/decryptstring";
exports.fileEncryptionApiEndpoint =
  "bx_block_dataencryption/dataencryption/encryptfile";
exports.fileDecryptionApiEndpoint =
  "bx_block_dataencryption/dataencryption/decryptfile";
exports.folderEncryptionApiEndpoint =
  "bx_block_dataencryption/dataencryption/encryptfolder";
exports.folderDecryptionApiEndpoint =
  "bx_block_dataencryption/dataencryption/decryptfolder";
exports.listEncryptedData = "bx_block_dataencryption/dataencryption/data_list";

exports.postApiMethod = "POST";
exports.getApiMethod = "GET";

exports.labelEncryptStr = "Encrypt String";
exports.labelEncryptFile = "Encrypt File";
exports.labelEncryptFolder = "Encrypt Folder";
exports.labelDecryptString = "Decrypt String";
exports.labelDecryptFile = "Decrypt File";
exports.labelDecryptFolder = "Decrypt Folder";

exports.loginDataEndPoint = "bx_block_login/logins";
exports.StringEncryptDataEndPoint =
  "bx_block_dataencryption/dataencryption/encryptstring";
exports.StringDecryptDataEndPoint =
  "bx_block_dataencryption/dataencryption/decryptstring";
exports.FileEncryptDataEndPoint =
  "bx_block_dataencryption/dataencryption/encryptfile";
exports.FileDecryptDataEndPoint =
  "bx_block_dataencryption/dataencryption/decryptfile";
exports.FolderEncryptDataEndPoint =
  "bx_block_dataencryption/dataencryption/encryptfolder";
exports.FolderDecryptDataEndPoint =
  "bx_block_dataencryption/dataencryption/decryptfolder";
exports.GetDataEncryptionEndPoint =
  "bx_block_dataencryption/dataencryption/data_list";
exports.GetDataEncryptedStringEndPoint =
  "bx_block_dataencryption/dataencryption/list_encrypted_strings";
exports.GetDataEncryptedFileEndPoint =
  "bx_block_dataencryption/dataencryption/list_encrypted_files";
exports.GetDataEncryptedFolderEndPoint =
  "bx_block_dataencryption/dataencryption/list_encrypted_folders";
exports.GetDataDecryptedStringEndPoint =
  "bx_block_dataencryption/dataencryption/list_decrypted_strings";
exports.GetDataDecryptedFileEndPoint =
  "bx_block_dataencryption/dataencryption/list_decrypted_files";
exports.GetDataDecryptedFolderEndPoint =
  "bx_block_dataencryption/dataencryption/list_decrypted_folders";
exports.GetStringDecryptionEndPoint =
  "bx_block_dataencryption/dataencryption/list_decrypted_strings";
exports.GetStringEncryptionEndPoint =
  "bx_block_dataencryption/dataencryption/list_encrypted_strings";
exports.GetFileDecryptionEndPoint =
  "bx_block_dataencryption/dataencryption/list_decrypted_files";
exports.GetFileEncryptionEndPoint =
  "bx_block_dataencryption/dataencryption/list_encrypted_files";
exports.GetFolderDecryptionEndPoint =
  "bx_block_dataencryption/dataencryption/list_decrypted_folders";
exports.GetFolderEncryptionEndPoint =
  "bx_block_dataencryption/dataencryption/list_encrypted_folders";

exports.loginWebEmail = "dataencryption@gmail.com";
exports.loginWebPassword = "Password@123";

exports.labelDownloadDropdownLabel = "History";
exports.labelDropDownEncryptedString = "Encrypted string history";
exports.labelDropDownEncryptedFile = "Encrypted file history";
exports.labelDropDownEncryptedFolder = "Encrypted folder history";
exports.labelDropDownDecryptedString = "Decrypted string history";
exports.labelDropDownDecryptedFile = "Decrypted file history";
exports.labelDropDownDecryptedFolder = "Decrypted folder history";
exports.labelDownloadData = "Download";
exports.loginEmail = "testNew@gmail.com";
exports.loginSecurityText = "Password@123";
exports.multiPartContentType =
  "multipart/form-data; boundary=<calculated when request is sent>";
// Customizable Area End
