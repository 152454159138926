import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { notification } from "antd";
import { ChangeEvent } from "react";
import {
  DecryptedStrResponse,
  EncryptedStrResponse,
  EncryptionDownloadType,
  EncryptionErrorResponseType,
  EncryptionGetListType,
  EncryptionLoginType,
  EncryptionValidResponseType,
  FileDecryptionType,
  FileEncryptionType,
  FolderDecryptionType,
  FolderEncryptionType,
  ResponseListDecrypted,
  ResponseListEncrypted,
  StrDecryptionType,
  StrEncryptionType,
} from "./types";
export const configJSON = require("./config.js");
// Customizable Area End

export type Props = {
  // Customizable Area Start
  id: string;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  strEncryptData: string;
  fileEncryptData: File | null;
  folderEncryptData: File | null;
  strDecryptData: string;
  fileDecryptData: File | null;
  folderDecryptData: File | null;
  strSelectEncryptData: string;
  fileSelectEncryptData: string;
  folderSelectEncryptData: string;
  strSelectData: string;
  fileSelectData: string;
  folderSelectData: string;
  token: string;
  selectedType: string;
  encryptedStr: string;
  decryptedStr: string;
  encryptedFile: string;
  decryptedFile: string;
  encryptedFolder: string;
  decryptedFolder: string;
  encryptionList: ResponseListEncrypted[];
  decryptionList: ResponseListDecrypted[];
  isShowStrData: boolean;
  isShowFileData: boolean;
  isShowFolderData: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class DataEncryptionController extends BlockComponent<
  // Customizable Area End
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postLoginApiCallId: string = "";
  postStrDataApiCallId: string = "";
  postFileDataApiCallId: string = "";
  postFolderDataApiCallId: string = "";
  getDataEncryptionListApiCallId: string = "";
  getDataDecryptionListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area Start
    this.state = {
      strEncryptData: "",
      fileEncryptData: null,
      folderEncryptData: null,
      strDecryptData: "",
      fileDecryptData: null,
      folderDecryptData: null,
      strSelectEncryptData: "",
      fileSelectEncryptData: "",
      folderSelectEncryptData: "",
      strSelectData: "",
      fileSelectData: "",
      folderSelectData: "",
      token: "",
      selectedType: "",
      encryptedStr: "",
      decryptedStr: "",
      encryptedFile: "",
      decryptedFile: "",
      encryptedFolder: "",
      decryptedFolder: "",
      decryptionList: [],
      encryptionList: [],
      isShowStrData: true,
      isShowFileData: false,
      isShowFolderData: false,
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    await this.handleLoginData();
    await this.handleGetDataEncryptionList("string");
    await this.handleGetDataDecryptionList("string");
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS,
  ): void {
    if (prevState.selectedType !== this.state.selectedType) {
      this.handleGetDataEncryptionList(this.state.selectedType);
      this.handleGetDataDecryptionList(this.state.selectedType);
    }
  }

  apiCall = async (details: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, apiType } = details;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method,
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "String" ? JSON.stringify(body) : body,
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (responseJson && !responseJson.error) {
        if (this.isValidResponseController(responseJson)) {
          this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
        }
      } else if (responseJson && responseJson.error) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);
      }
    }
  }

  isValidResponseController = (responseJson: EncryptionValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  apiSuccessCallBacksController = (
    apiRequestCallId: string,
    responseJson: EncryptionGetListType &
      EncryptionLoginType &
      StrEncryptionType &
      FileEncryptionType &
      FolderEncryptionType &
      StrDecryptionType &
      FileDecryptionType &
      FolderDecryptionType &
      DecryptedStrResponse &
      EncryptedStrResponse,
  ) => {
    if (apiRequestCallId === this.postStrDataApiCallId) {
      this.submitStrApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postFileDataApiCallId) {
      this.submitFileDataApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postFolderDataApiCallId) {
      this.submitFolderDataApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postLoginApiCallId) {
      this.loginApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDataEncryptionListApiCallId) {
      this.allEncryptionListApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDataDecryptionListApiCallId) {
      this.allDecryptionListApiSuccessCallBack(responseJson);
    }
  };

  apiFailureCallBacksController = (
    apiRequestCallId: string,
    responseJson: EncryptionErrorResponseType,
  ) => {
    if (apiRequestCallId === this.postStrDataApiCallId) {
      notification["error"]({
        message: responseJson.error,
      });
    }
    if (apiRequestCallId === this.postFileDataApiCallId) {
      notification["error"]({
        message: responseJson.error,
      });
    }
    if (apiRequestCallId === this.postFolderDataApiCallId) {
      notification["error"]({
        message: responseJson.error,
      });
    }
    if (apiRequestCallId === this.postLoginApiCallId) {
      notification["error"]({
        message: "Get Token Failed. Please try again!",
      });
    }
  };

  submitStrApiSuccessCallBack = (
    responseJson: StrEncryptionType & StrDecryptionType,
  ) => {
    if (responseJson.message === "String Encrypted successfully.") {
      const encryptedData = responseJson.data;
      this.setState({ encryptedStr: encryptedData });
      notification["success"]({
        message: "String Encrypted Successfully",
      });
      this.handleGetDataEncryptionList("string");
    }
    if (responseJson.message === "String Decrypted successfully.") {
      this.setState({ decryptedStr: responseJson.data });
      notification["success"]({
        message: "String Decrypted Successfully",
      });
      this.handleGetDataDecryptionList("string");
    }
  };

  submitFileDataApiSuccessCallBack = (
    responseJson: FileEncryptionType & FileDecryptionType,
  ) => {
    if (responseJson.message === "File encrypted and uploaded to Minio.") {
      const encryptedFileData = responseJson.data;
      this.setState({ encryptedFile: encryptedFileData });
      notification["success"]({
        message: "File Encrypted Successfully",
      });
      this.handleGetDataEncryptionList("file");
    }
    if (responseJson.message === "File decrypted and uploaded to Minio.") {
      const decryptedFileData = responseJson.data;
      this.setState({ decryptedFile: decryptedFileData });
      notification["success"]({
        message: "File Decrypted Successfully",
      });
      this.handleGetDataDecryptionList("file");
    }
  };

  submitFolderDataApiSuccessCallBack = (
    responseJson: FolderEncryptionType & FolderDecryptionType,
  ) => {
    if (responseJson.message === "Folder encrypted and uploaded to Minio.") {
      const encryptedFolderData = responseJson.data;
      this.setState({ encryptedFolder: encryptedFolderData });
      notification["success"]({
        message: "Folder Encrypted Successfully",
      });
      this.handleGetDataEncryptionList("folder");
    }
    if (responseJson.message === "Folder decrypted and uploaded to Minio.") {
      const decryptedFolderData = responseJson.data;
      this.setState({ decryptedFolder: decryptedFolderData });
      notification["success"]({
        message: "Folder Decrypted Successfully",
      });
      this.handleGetDataDecryptionList("folder");
    }
  };

  loginApiSuccessCallBack = (responseJson: EncryptionLoginType) => {
    if (responseJson) {
      this.setState({
        token: responseJson.meta.token,
      });
    }
  };

  allEncryptionListApiSuccessCallBack = (
    responseJson: EncryptedStrResponse,
  ) => {
    if (responseJson?.data) {
      const encryptedList = responseJson.data;
      this.setState({
        encryptionList: [...encryptedList],
      });
    }
  };
  allDecryptionListApiSuccessCallBack = (
    responseJson: DecryptedStrResponse,
  ) => {
    if (responseJson?.data) {
      const decryptedList = responseJson.data;
      this.setState({
        decryptionList: [...decryptedList],
      });
    }
  };

  handleStrData = () => {
    this.setState({
      isShowStrData: true,
      isShowFileData: false,
      isShowFolderData: false,
      selectedType: "string",
      encryptedFile: "",
      decryptedFile: "",
      encryptedFolder: "",
      decryptedFolder: "",
    });
  };

  handleFileData = () => {
    this.setState({
      isShowStrData: false,
      isShowFileData: true,
      isShowFolderData: false,
      selectedType: "file",
      encryptedStr: "",
      decryptedStr: "",
      encryptedFolder: "",
      decryptedFolder: "",
    });
  };

  handleFolderData = () => {
    this.setState({
      isShowStrData: false,
      isShowFileData: false,
      isShowFolderData: true,
      selectedType: "folder",
      encryptedStr: "",
      decryptedStr: "",
      encryptedFile: "",
      decryptedFile: "",
    });
  };

  setStrEncryptData = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ strEncryptData: event.target.value });
  };

  setStrDecryptData = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ strDecryptData: event.target.value });
  };

  setFileEncryptData = (event: ChangeEvent<HTMLInputElement>) => {
    const getFile = event.target.files?.[0];
    this.setState({ fileEncryptData: getFile || null });
  };

  setFileDecryptData = (event: ChangeEvent<HTMLInputElement>) => {
    const getFile = event.target.files?.[0];
    this.setState({ fileDecryptData: getFile || null });
  };

  setFolderEncryptData = (event: ChangeEvent<HTMLInputElement>) => {
    const getFile = event.target.files?.[0];
    this.setState({ folderEncryptData: getFile || null });
  };

  setFolderDecryptData = (event: ChangeEvent<HTMLInputElement>) => {
    const getFile = event.target.files?.[0];
    this.setState({ folderDecryptData: getFile || null });
  };

  handleStrEncryptionOnfinish = (selectedType: string) => {
    if (selectedType === "String") {
      this.handlePostStrData("encrypt");
    } else if (selectedType === "File") {
      this.handlePostFileData("encrypt");
    } else {
      this.handlePostFolderData("encrypt");
    }
  };

  handleDownload = (selectedFile: EncryptionDownloadType) => {
    if (this.state.selectedType === "folder") {
      const link = document.createElement("a");
      if (selectedFile.downloadUrl) {
        link.href = selectedFile.downloadUrl;
      }

      link.download = selectedFile.filename;
      link.click();
    } else {
      const fileUrl = selectedFile.downloadUrl;
      const xhrRequest = new XMLHttpRequest();
      xhrRequest.open("GET", fileUrl, true);
      xhrRequest.responseType = "blob";
      xhrRequest.onload = function () {
        if (xhrRequest.status === 200) {
          const blob = xhrRequest.response;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = selectedFile.filename;

          link.click();
        }
      };

      xhrRequest.send();
    }
  };

  handleFileDownload = () => {
    this.handleDownload({
      downloadUrl: this.state.encryptedFile,
      urlFile: null,
      filename: "encryptedFile",
    });
  };

  handleSelectEncryptFileDownload = () => {
    this.handleDownload({
      downloadUrl: this.state.fileSelectEncryptData,
      urlFile: null,
      filename: "encryptedFile",
    });
  };

  handleSelectFileDownload = () => {
    this.handleDownload({
      downloadUrl: this.state.fileSelectData,
      urlFile: null,
      filename: "decryptedFile",
    });
  };

  handleDecryptedFileDownload = () => {
    this.handleDownload({
      downloadUrl: this.state.decryptedFile,
      urlFile: null,
      filename: "decryptedFile",
    });
  };

  handleFolderDownload = () => {
    this.handleDownload({
      downloadUrl: this.state.encryptedFolder,
      urlFile: null,
      filename: "sample.zip",
    });
  };

  handleSelectEncryptFolderDownload = () => {
    this.handleDownload({
      downloadUrl: this.state.folderSelectEncryptData,
      urlFile: null,
      filename: "sample.zip",
    });
  };

  handleSelectFolderDownload = () => {
    this.handleDownload({
      downloadUrl: this.state.folderSelectData,
      urlFile: null,
      filename: "sample.zip",
    });
  };

  handleDecryptedFolderDownload = () => {
    this.handleDownload({
      downloadUrl: this.state.decryptedFolder,
      urlFile: null,
      filename: "sample.zip",
    });
  };

  handleLoginData = async () => {
    const bodyRequest = {
      data: {
        attributes: {
          email: configJSON.loginWebEmail,
          password: configJSON.loginWebPassword,
        },
        type: "email_account",
      },
    };

    this.postLoginApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: "POST",
      endPoint: configJSON.loginDataEndPoint,
      body: JSON.stringify(bodyRequest),
    });
  };

  handleGetDataEncryptionList = async (selectedType: string) => {
    const apiUrl = this.handleEndpoints(selectedType, "Encrypt");
    this.getDataEncryptionListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethod,
      endPoint: apiUrl,
    });
  };

  handleGetDataDecryptionList = async (selectedType: string) => {
    const apiUrl = this.handleEndpoints(selectedType, "Decrypt");
    this.getDataDecryptionListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethod,
      endPoint: apiUrl,
    });
  };

  handleEndpoints = (selectedType: string, apiType: string) => {
    if (apiType === "Encrypt") {
      switch (selectedType) {
        case "string":
          return configJSON.GetStringEncryptionEndPoint;

        case "file":
          return configJSON.GetFileEncryptionEndPoint;

        case "folder":
          return configJSON.GetFolderEncryptionEndPoint;
        default:
          break;
      }
    } else {
      switch (selectedType) {
        case "string":
          return configJSON.GetStringDecryptionEndPoint;

        case "file":
          return configJSON.GetFileDecryptionEndPoint;

        case "folder":
          return configJSON.GetFolderDecryptionEndPoint;
        default:
          break;
      }
    }
  };

  handlePostStrData = async (selectedType: string) => {
    const formData = {
      string:
        selectedType === "encrypt"
          ? this.state.strEncryptData
          : this.state.strDecryptData,
    };

    this.postStrDataApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint:
        selectedType === "encrypt"
          ? configJSON.StringEncryptDataEndPoint
          : configJSON.StringDecryptDataEndPoint,
      body: formData,
      apiType: "String",
    });
  };

  handlePostSelectEncryptedStrData = async () => {
    const formData = new FormData();
    formData.append("string", this.state.strSelectEncryptData);

    this.postStrDataApiCallId = await this.apiCall({
      method: "POST",
      endPoint: configJSON.StringEncryptDataEndPoint,
      body: formData,
      apiType: "formdata",
    });
  };

  handlePostSelectDecryptedStrData = async () => {
    const formData = new FormData();
    formData.append("string", this.state.strSelectData);

    this.postStrDataApiCallId = await this.apiCall({
      method: "POST",
      endPoint: configJSON.StringDecryptDataEndPoint,
      body: formData,
      apiType: "formdata",
    });
  };

  handlePostFileData = async (selectedType: string) => {
    const formData = new FormData();
    const fileContent =
      selectedType === "encrypt"
        ? this.state.fileEncryptData
        : this.state.fileDecryptData;

    if (fileContent) {
      formData.append("file", fileContent);
    }
    this.postFileDataApiCallId = await this.apiCall({
      method: "POST",
      endPoint:
        selectedType === "encrypt"
          ? configJSON.FileEncryptDataEndPoint
          : configJSON.FileDecryptDataEndPoint,
      body: formData,
      apiType: "formdata",
    });
  };

  handlePostFolderData = async (selectedType: string) => {
    const formData = new FormData();
    const zipFileContent =
      selectedType === "encrypt"
        ? this.state.folderEncryptData
        : this.state.folderDecryptData;

    if (zipFileContent) {
      formData.append("folder", zipFileContent);
    }
    this.postFolderDataApiCallId = await this.apiCall({
      method: "POST",
      endPoint:
        selectedType === "encrypt"
          ? configJSON.FolderEncryptDataEndPoint
          : configJSON.FolderDecryptDataEndPoint,
      body: formData,
      apiType: "formdata",
    });
  };

  getOnChangeFunction = (event: string, selectedType: string) => {
    if (selectedType === "String") {
      this.setState({
        strSelectEncryptData: event,
        strEncryptData: event,
      });
    } else if (selectedType === "File") {
      this.setState({ fileSelectEncryptData: event });
    } else {
      this.setState({ folderSelectEncryptData: event });
    }
  };

  getEncryptionDataTestId = (selectedType: string) => {
    let encryptionDataTestId;
    if (selectedType === "String") {
      encryptionDataTestId = "stringEncryptionButtonDataID";
    } else if (selectedType === "File") {
      encryptionDataTestId = "fileEncryptionButtonDataID";
    } else {
      encryptionDataTestId = "folderEncryptionButtonDataID";
    }
    return encryptionDataTestId;
  };

  getOnChangeDecryption = (event: string, selectedType: string) => {
    if (selectedType === "String") {
      this.setState({ strSelectData: event, strDecryptData: event });
    } else if (selectedType === "File") {
      this.setState({ fileSelectData: event });
    } else {
      this.setState({ folderSelectData: event });
    }
  };

  getOnFinishDecryptionFunction = (selectedType: string) => {
    let onFinishDecryptionFunction;
    if (selectedType === "String") {
      onFinishDecryptionFunction = this.handlePostStrData;
    } else if (selectedType === "File") {
      onFinishDecryptionFunction = this.handlePostFileData;
    } else {
      onFinishDecryptionFunction = this.handlePostFolderData;
    }
    return onFinishDecryptionFunction;
  };

  getDecryptionDataTestId = (selectedType: string) => {
    let decryptionDataTestId;
    if (selectedType === "String") {
      decryptionDataTestId = "stringDecryptionButtonDataID";
    } else if (selectedType === "File") {
      decryptionDataTestId = "fileDecryptionButtonDataID";
    } else {
      decryptionDataTestId = "folderDecryptionButtonDataID";
    }
    return decryptionDataTestId;
  };
}
// Customizable Area End
