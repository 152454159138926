// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { createRequestMessage } from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "framework/src/Helpers/handle-response-message";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider";
import { ProfileData, ResponseProfileTemplate, SummaryData } from "../__mocks__/Types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authTokenPublicResume: string;
  publicResumes: SummaryData[];
  isViewProfilePublicResume: boolean;
  profileDataPublicResume: ProfileData;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CvresumeCandidateListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPublicResumesCallId: string = "";
  getPublicProfileCallId: string = "";
  getPublicCandidateDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      publicResumes: [],
      authTokenPublicResume: "",
      isViewProfilePublicResume: false,
      profileDataPublicResume: {
        name: "",
        age: 0,
        years_of_experience: 0,
        experience_summary: "",
        education_summary: "",
        created_by: "",
        updated_by: "",
        created_at: "",
        updated_at: "",
        experience_file: "",
        education_file: "",
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const publicResponseDataJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const publicErrorDataJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      switch (apiRequestCallDataId) {
        case this.getPublicResumesCallId: {
          handleResponseMessage({
            responseJson: publicResponseDataJson,
            errorJson: publicErrorDataJson,
            onSuccess: () => {
              this.onSuccessGetResumesList(publicResponseDataJson);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Cv resume Failed! Please retry");
            },
          });
          break;
        }
        case this.getPublicCandidateDetails: {
          handleResponseMessage({
            responseJson: publicResponseDataJson,
            errorJson: publicErrorDataJson,
            onSuccess: () => {
              this.onSuccessGetCandidatesListComp(publicResponseDataJson);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Cv resume Failed! Please retry");
            },
          });
          break;
        }
        case this.getPublicProfileCallId: {
          handleResponseMessage({
            responseJson: publicResponseDataJson,
            errorJson: publicErrorDataJson,
            onSuccess: () => {
              this.onSuccessGetCandidateInfo(publicResponseDataJson);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Cv resume Failed! Please retry");
            },
          });
          break;
        }
      }
    }

    // Customizable Area End
  }
  // web events

  // Customizable Area Start

  onSuccessGetResumesList = (response: { data: null | SummaryData[] }) => {
    if (response.data) {
      this.setState({ publicResumes: response.data });
    }
  };
  onSuccessGetCandidateInfo = (response: ResponseProfileTemplate) => {
    if (response.data !== null && response.data?.attributes) {
      this.setState({
        profileDataPublicResume: response.data?.attributes,
      });
    }
  };

  getPublicallyAvailableResume = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getPublicResumesCallId = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authTokenPublicResume,
    };
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.publicResume_EndPoint,
      method: configJSON.getApiMethodType,
      header: headers,
    });
  };

  async componentDidMount() {
    const publicToken: null | string = await storage.get("token");
    if (publicToken) {
      this.setState(
        {
          authTokenPublicResume: publicToken,
        },
        () => {
          this.getPublicallyAvailableResume();
        },
      );
    }
  }

  toggleProfileWeb = (userId: string) => {
    if (userId === "") {
      this.setState({
        isViewProfilePublicResume: false,
      });
    } else {
      this.setState({
        isViewProfilePublicResume: true,
      });
      this.getCandidateDetailsById(userId);
    }

  };

  getCandidateDetailsById = (getId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getPublicProfileCallId = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authTokenPublicResume,
    };
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.profile_EndPoint}/${getId}`,
      method: configJSON.getApiMethodType,
      header: headers,
    });
  };

  onSuccessGetCandidatesListComp = (
    responseDataJson: ResponseProfileTemplate,
  ) => {
    if (responseDataJson?.data !== null && responseDataJson.data.attributes) {
      this.setState({
        profileDataPublicResume: responseDataJson.data?.attributes,
      });
    }
  };
  // Customizable Area End
}
