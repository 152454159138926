import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";

import VideoBackgroundListAdapter from "../../blocks/adapters/src/VideoBackgroundListAdapter";
import VideoBackgroundInputAdapter from "../../blocks/adapters/src/VideoInputAdapter";
import VideoBackgroundEditAdapter from "../../blocks/adapters/src/VideoBackgroundEditAdapter"
import VideoBackgroundPageAdapter from "../../blocks/adapters/src/VideoPageListAdapter"

//Assembler generated adapters start
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const videoBackgroundListAdapter = new VideoBackgroundListAdapter();
const videoBackgroundInputAdapter = new VideoBackgroundInputAdapter()
const videoBackgroundEditAdapter = new VideoBackgroundEditAdapter()
const videoBackgroundPageAdapter = new VideoBackgroundPageAdapter()

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to 21KSharedServicesINR!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem content={'Data Encryption'} onPress={() => navigation.navigate("DataEncryption")} />
            <CustomTextItem content={'Video Backgrounds'} onPress={() => navigation.navigate("VideoBackgrounds")} />
            <CustomTextItem
              content="ContentModeration"
              onPress={() => navigation.navigate("ContentModeration")}
            />

            <CustomTextItem content={'Information Page'} onPress={() => navigation.navigate("InfoPage")} />
            <CustomTextItem content={'Alert'} onPress={() => this.showAlert("Example", "This happened")} />
            <CustomTextItem content={'Catalogue'} onPress={() => navigation.navigate("Catalogue")} />
            <CustomTextItem content={'Core'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Advanced Search'} onPress={() => navigation.navigate("AdvancedSearch")} />
            <CustomTextItem content={'Categories Subcategories'} onPress={() => navigation.navigate("Categoriessubcategories")} />
            <CustomTextItem content={'Phone Number Input'} onPress={() => navigation.navigate("PhoneNumberInput")} />
            <CustomTextItem content={'Country Code Selector'} onPress={() => navigation.navigate("CountryCodeSelector")} />
            <CustomTextItem content={'OTP Input Auth'} onPress={() => navigation.navigate("OTPInputAuth")} />
            <CustomTextItem content={'Email Account Registration'} onPress={() => navigation.navigate("EmailAccountRegistration")} />
            <CustomTextItem content={'Social Media Account Registration Screen'} onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
            <CustomTextItem content={'Social Media Account'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Visual Analytics'} onPress={() => navigation.navigate("VisualAnalytics")} />
            <CustomTextItem content={'Scheduling'} onPress={() => navigation.navigate("Scheduling")} />
            <CustomTextItem content={'Carousel Display'} onPress={() => navigation.navigate("CarouselDisplay")} />
            <CustomTextItem content={'Analytics'} onPress={() => navigation.navigate("Analytics")} />
            <CustomTextItem content={'Comments'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Post Creation'} onPress={() => navigation.navigate("PostCreation")} />
            <CustomTextItem content={'Custom Form'} onPress={() => navigation.navigate("Customform")} />
            <CustomTextItem content={'Document Opener'} onPress={() => navigation.navigate("DocumentOpener")} />
            <CustomTextItem content={'Pledge Tracking'} onPress={() => navigation.navigate("PledgeTracking")} />
            <CustomTextItem content={'Utilities'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Feedback Collection'} onPress={() => navigation.navigate("FeedbackCollection")} />
            <CustomTextItem content={'Bulk Uploading'} onPress={() => navigation.navigate("BulkUploading")} />
            <CustomTextItem content={'Admin Console'} onPress={() => navigation.navigate("AdminConsole")} />
            <CustomTextItem content={'Activity Feed'} onPress={() => navigation.navigate("ActivityFeed")} />
            <CustomTextItem content={'Chat'} onPress={() => navigation.navigate("Chat9")} />
            <CustomTextItem content={'Content Management'} onPress={() => navigation.navigate("ContentManagement")} />
            <CustomTextItem content={'Email Notifications'} onPress={() => navigation.navigate("EmailNotifications2")} />
            <CustomTextItem content={'Pin Conversation'} onPress={() => navigation.navigate("PinConversation")} />
            <CustomTextItem content={'Spend Analysis'} onPress={() => navigation.navigate("SpendAnalysis")} />
            <CustomTextItem content={'Privacy Settings'} onPress={() => navigation.navigate("PrivacySettings")} />
            <CustomTextItem content={'Invoice Billing'} onPress={() => navigation.navigate("InvoiceBilling")} />
            <CustomTextItem content={'Item Availability'} onPress={() => navigation.navigate("ItemAvailability")} />
            <CustomTextItem content={'Referrals'} onPress={() => navigation.navigate("Referrals")} />
            <CustomTextItem content={'Leave Tracker'} onPress={() => navigation.navigate("LeaveTracker")} />
            <CustomTextItem content={'Leader Board'} onPress={() => navigation.navigate("Leaderboard")} />
            <CustomTextItem content={'Summary Card'} onPress={() => navigation.navigate("SummaryCard")} />
            <CustomTextItem content={'Logo Design'} onPress={() => navigation.navigate("LogoDesign2")} />
            <CustomTextItem content={'Wiki'} onPress={() => navigation.navigate("Wiki2")} />
            <CustomTextItem content={'Cv Resume Candidate Management'} onPress={() => navigation.navigate("CvresumeCandidateManagement2")} />
            <CustomTextItem content={'Screen Sharing'} onPress={() => navigation.navigate("ScreenSharing")} />
            <CustomTextItem content={'File Attachment'} onPress={() => navigation.navigate("FileAttachment")} />
            <CustomTextItem content={'Match Algorithm'} onPress={() => navigation.navigate("MatchAlgorithm2")} />
            <CustomTextItem content={'Organisation Hierarchy'} onPress={() => navigation.navigate("OrganisationHierarchy")} />
            <CustomTextItem content={'Goal Management'} onPress={() => navigation.navigate("GoalManagement")} />
            <CustomTextItem content={'Emergency SOS'} onPress={() => navigation.navigate("EmergencySos")} />
            <CustomTextItem content={'Password Protected Pages'} onPress={() => navigation.navigate("PasswordProtectedPages")} />
            <CustomTextItem content={'Manage Blog Comments'} onPress={() => navigation.navigate("ManageBlogComments")} />
            <CustomTextItem content={'Expense Tracking'} onPress={() => navigation.navigate("ExpenseTracking")} />
            <CustomTextItem content={'Localinternational Shipping'} onPress={() => navigation.navigate("LocalinternationalShipping")} />
            <CustomTextItem content={'Payroll Integration'} onPress={() => navigation.navigate("PayrollIntegration2")} />
            <CustomTextItem content={'Tax Calculator'} onPress={() => navigation.navigate("TaxCalculator")} />
            <CustomTextItem content={'Timesheet Management'} onPress={() => navigation.navigate("TimesheetManagement")} />
            <CustomTextItem content={'Assessment Test'} onPress={() => navigation.navigate("AssessmentTest")} />
            <CustomTextItem content={'Location Based Alerts'} onPress={() => navigation.navigate("LocationbasedAlerts")} />
            <CustomTextItem content={'Blog Importing'} onPress={() => navigation.navigate("BlogImporting")} />
            <CustomTextItem content={'Breadcrumb Navigation'} onPress={() => navigation.navigate("BreadcrumbNavigation")} />
            <CustomTextItem content={'Budgeting Forecasting'} onPress={() => navigation.navigate("BudgetingForecasting")} />
            <CustomTextItem content={'Centralised Billing'} onPress={() => navigation.navigate("CentralisedBilling")} />
            <CustomTextItem content={'Certification Tracking'} onPress={() => navigation.navigate("CertificationTracking")} />
            <CustomTextItem content={'Collect TransactionFees'} onPress={() => navigation.navigate("CollectTransactionFees")} />
            <CustomTextItem content={'Content Moderation'} onPress={() => navigation.navigate("ContentModeration")} />
            <CustomTextItem content={'Content Flag'} onPress={() => navigation.navigate("ContentFlag")} />
            <CustomTextItem content={'Mentions Tagging'} onPress={() => navigation.navigate("Mentionstagging")} />
            <CustomTextItem content={'Live Streaming'} onPress={() => navigation.navigate("LiveStreaming")} />
            <CustomTextItem content={'Language Detection'} onPress={() => navigation.navigate("LanguageDetection")} />
            <CustomTextItem content={'Portfolio Management'} onPress={() => navigation.navigate("PortfolioManagement")} />
            <CustomTextItem content={'ContentModeration'}  onPress={() => navigation.navigate("ContentModeration")} />
           
            <CustomTextItem
              content="InfoPage"
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content="Alert"
              onPress={() => this.showAlert("Example", "This happened")}
            />
            <CustomTextItem
              content="Catalogue"
              onPress={() => navigation.navigate("Catalogue")}
            />
            <CustomTextItem
              content="core"
              onPress={() =>
                this.showAlert("Error", "Could not determine assembler export")
              }
            />
            <CustomTextItem
              content="AdvancedSearch"
              onPress={() => navigation.navigate("AdvancedSearch")}
            />
            <CustomTextItem
              content="Categoriessubcategories"
              onPress={() => navigation.navigate("Categoriessubcategories")}
            />
            <CustomTextItem
              content="PhoneNumberInput"
              onPress={() => navigation.navigate("PhoneNumberInput")}
            />
            <CustomTextItem
              content="CountryCodeSelector"
              onPress={() => navigation.navigate("CountryCodeSelector")}
            />
            <CustomTextItem
              content="OTPInputAuth"
              onPress={() => navigation.navigate("OTPInputAuth")}
            />
            <CustomTextItem
              content="EmailAccountRegistration"
              onPress={() => navigation.navigate("EmailAccountRegistration")}
            />
            <CustomTextItem
              content="SocialMediaAccountRegistrationScreen"
              onPress={() =>
                navigation.navigate("SocialMediaAccountRegistrationScreen")
              }
            />
            <CustomTextItem
              content="social-media-account"
              onPress={() =>
                this.showAlert("Error", "Could not determine assembler export")
              }
            />
            <CustomTextItem
              content="VisualAnalytics"
              onPress={() => navigation.navigate("VisualAnalytics")}
            />
            <CustomTextItem
              content="Scheduling"
              onPress={() => navigation.navigate("Scheduling")}
            />
            <CustomTextItem
              content="CarouselDisplay"
              onPress={() => navigation.navigate("CarouselDisplay")}
            />
            <CustomTextItem
              content="Analytics"
              onPress={() => navigation.navigate("Analytics")}
            />
            <CustomTextItem
              content="comments"
              onPress={() =>
                this.showAlert("Error", "Could not determine assembler export")
              }
            />
            <CustomTextItem
              content="PostCreation"
              onPress={() => navigation.navigate("PostCreation")}
            />
            <CustomTextItem
              content="Customform"
              onPress={() => navigation.navigate("Customform")}
            />
            <CustomTextItem
              content="DocumentOpener"
              onPress={() => navigation.navigate("DocumentOpener")}
            />
            <CustomTextItem
              content="PledgeTracking"
              onPress={() => navigation.navigate("PledgeTracking")}
            />
            <CustomTextItem
              content="utilities"
              onPress={() =>
                this.showAlert("Error", "Could not determine assembler export")
              }
            />
            <CustomTextItem
              content="FeedbackCollection"
              onPress={() => navigation.navigate("FeedbackCollection")}
            />
            <CustomTextItem
              content="BulkUploading"
              onPress={() => navigation.navigate("BulkUploading")}
            />
            <CustomTextItem
              content="AdminConsole"
              onPress={() => navigation.navigate("AdminConsole")}
            />
            <CustomTextItem
              content="ActivityFeed"
              onPress={() => navigation.navigate("ActivityFeed")}
            />
            <CustomTextItem
              content="Chat9"
              onPress={() => navigation.navigate("Chat9")}
            />
            <CustomTextItem
              content="ContentManagement"
              onPress={() => navigation.navigate("ContentManagement")}
            />
            <CustomTextItem
              content="EmailNotifications2"
              onPress={() => navigation.navigate("EmailNotifications2")}
            />
            <CustomTextItem
              content="PinConversation"
              onPress={() => navigation.navigate("PinConversation")}
            />
            <CustomTextItem
              content="SpendAnalysis"
              onPress={() => navigation.navigate("SpendAnalysis")}
            />
            <CustomTextItem
              content="PrivacySettings"
              onPress={() => navigation.navigate("PrivacySettings")}
            />
            <CustomTextItem
              content="InvoiceBilling"
              onPress={() => navigation.navigate("InvoiceBilling")}
            />
            <CustomTextItem
              content="ItemAvailability"
              onPress={() => navigation.navigate("ItemAvailability")}
            />
            <CustomTextItem
              content="Referrals"
              onPress={() => navigation.navigate("Referrals")}
            />
            <CustomTextItem
              content="LeaveTracker"
              onPress={() => navigation.navigate("LeaveTracker")}
            />
            <CustomTextItem
              content="Leaderboard"
              onPress={() => navigation.navigate("Leaderboard")}
            />
            <CustomTextItem
              content="SummaryCard"
              onPress={() => navigation.navigate("SummaryCard")}
            />
            <CustomTextItem
              content="LogoDesign2"
              onPress={() => navigation.navigate("LogoDesign2")}
            />
            <CustomTextItem
              content="Wiki2"
              onPress={() => navigation.navigate("Wiki2")}
            />
            <CustomTextItem
              content="CvresumeCandidateManagement2"
              onPress={() =>
                navigation.navigate("CvresumeCandidateManagement2")
              }
            />
            <CustomTextItem
              content="ScreenSharing"
              onPress={() => navigation.navigate("ScreenSharing")}
            />
            <CustomTextItem
              content="FileAttachment"
              onPress={() => navigation.navigate("FileAttachment")}
            />
            <CustomTextItem
              content="MatchAlgorithm2"
              onPress={() => navigation.navigate("MatchAlgorithm2")}
            />
            <CustomTextItem
              content="DataEncryption"
              onPress={() => navigation.navigate("DataEncryption")}
            />
            <CustomTextItem
              content="VideoBackgrounds"
              onPress={() => navigation.navigate("VideoBackgrounds")}
            />
            <CustomTextItem
              content="OrganisationHierarchy"
              onPress={() => navigation.navigate("OrganisationHierarchy")}
            />
            <CustomTextItem
              content="GoalManagement"
              onPress={() => navigation.navigate("GoalManagement")}
            />
            <CustomTextItem
              content="EmergencySos"
              onPress={() => navigation.navigate("EmergencySos")}
            />
            <CustomTextItem
              content="PasswordProtectedPages"
              onPress={() => navigation.navigate("PasswordProtectedPages")}
            />
            <CustomTextItem
              content="ManageBlogComments"
              onPress={() => navigation.navigate("ManageBlogComments")}
            />
            <CustomTextItem
              content="ExpenseTracking"
              onPress={() => navigation.navigate("ExpenseTracking")}
            />
            <CustomTextItem
              content="LocalInternationalShipping"
              onPress={() => navigation.navigate("LocalInternationalShipping")}
            />
            <CustomTextItem
              content="PayrollIntegration2"
              onPress={() => navigation.navigate("PayrollIntegration2")}
            />
            <CustomTextItem
              content="TaxCalculator"
              onPress={() => navigation.navigate("TaxCalculator")}
            />
            <CustomTextItem
              content="TimesheetManagement"
              onPress={() => navigation.navigate("TimesheetManagement")}
            />
            <CustomTextItem
              content="AssessmentTest"
              onPress={() => navigation.navigate("AssessmentTest")}
            />
            <CustomTextItem
              content="LocationbasedAlerts"
              onPress={() => navigation.navigate("LocationbasedAlerts")}
            />
            <CustomTextItem
              content="BlogImporting"
              onPress={() => navigation.navigate("BlogImporting")}
            />
            <CustomTextItem
              content="BreadcrumbNavigation"
              onPress={() => navigation.navigate("BreadcrumbNavigation")}
            />
            <CustomTextItem
              content="BudgetingForecasting"
              onPress={() => navigation.navigate("BudgetingForecasting")}
            />
            <CustomTextItem
              content="CentralisedBilling"
              onPress={() => navigation.navigate("CentralisedBilling")}
            />
            <CustomTextItem
              content="CertificationTracking"
              onPress={() => navigation.navigate("CertificationTracking")}
            />
            <CustomTextItem
              content="CollectTransactionFees"
              onPress={() => navigation.navigate("CollectTransactionFees")}
            />
            {/* <CustomTextItem content={'ContentModeration'}  onPress={() => navigation.navigate("ContentModeration")} /> */}
            <CustomTextItem
              content="ContentFlag"
              onPress={() => navigation.navigate("ContentFlag")}
            />
            <CustomTextItem
              content="Mentionstagging"
              onPress={() => navigation.navigate("Mentionstagging")}
            />
            <CustomTextItem
              content="LiveStreaming"
              onPress={() => navigation.navigate("LiveStreaming")}
            />
            <CustomTextItem
              content="LanguageDetection"
              onPress={() => navigation.navigate("LanguageDetection")}
            />
            <CustomTextItem
              content="PortfolioManagement"
              onPress={() => navigation.navigate("PortfolioManagement")}
            />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;