import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  Modal,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  ThemeProvider,
  createTheme
} from "@material-ui/core";
import { RemoveRedEye, Search, Tune, Delete, Create} from '@material-ui/icons';
import { imgProfile } from "./assets";
import { SummaryData } from "../__mocks__/Types";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CvresumeCandidateManagement2Controller, {
  Props,
} from "./CvresumeCandidateManagement2Controller.web";

class CvresumeCandidateManagement2 extends CvresumeCandidateManagement2Controller {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  renderAllResume = () => {
    const filterItemsData = this.handleResumeWebFilterData();
    return (
      <Box sx={webStyle.listContainer}>
        {filterItemsData.length !== 0 ? (
          filterItemsData.map((item: SummaryData, index: number) => {
            return (
              <Box sx={webStyle.listItemsContainer} key={item.id}>
                <Box sx={webStyle.listBoxItem}>
                  <Box sx={webStyle.listBoxNameAndSummaryItem}>Name:</Box>
                  <Box sx={webStyle.listBoxItemData}>
                    {item?.attributes?.title}
                  </Box>
                </Box>
                <Box sx={webStyle.listBoxItem}>
                  <Box sx={webStyle.listBoxNameAndSummaryItem}>Summary:</Box>
                  <Box sx={webStyle.listBoxItemData}>
                    {item?.attributes?.summary}
                  </Box>
                </Box>
                <Box sx={webStyle.listBoxItem}>
                  <Box sx={webStyle.listBoxPublishTypeItem}>Publish type:</Box>
                  <Box sx={webStyle.listBoxItemData}>
                    {item?.attributes?.allow_publish.toString()}
                  </Box>
                </Box>
                <Box sx={webStyle.listBoxItemButtons}>
                  <Link
                    target="_blank"
                    style={webStyle.eyeBtn}
                    data-test-id={`downloadResumeBtn-${index}`}
                    href={item?.attributes.resume?.toString()}
                  >
                    <RemoveRedEye fontSize="large" />
                  </Link>
                  <Button
                    style={webStyle.deleteBtn}
                    data-test-id={`delResumeBtn-${index}`}
                    onClick={() => this.deleteResumeById(item?.id)}
                  >
                    <Delete fontSize="large" />
                  </Button>
                  <Button
                    style={webStyle.editBtn}
                    data-test-id={`editResumeBtn-${index}`}
                    onClick={() => this.handleEditResumeModalOpen(item)}
                  >
                    <Create fontSize="large" />
                  </Button>
                </Box>
              </Box>);
          })
        ) : (
          <Typography style={webStyle.headingMyResumes}>No Resume available</Typography>
        )}
      </Box>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderEditResumeModal = () => {
    return (
      <Modal open={this.state.isEditResumeWeb} style={webStyle.modal}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.modalContainer}>
            <Typography variant="h5" style={webStyle.modalHeading}>
              Update Resume
            </Typography>
            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                placeholder="Enter the title for this resume"
                style={webStyle.modalTextInput}
                value={this.state.titleWeb}
                data-test-id="editResumeTitleInput"
                onChange={this.handleTitleResumeInputChangeText}
              />
            </Box>
            <Box style={webStyle.modalTextInputFileBox}>
              <Input
                type="file"
                id="updateResumeFile"
                style={webStyle.modalTextInputFile}
                inputProps={{ accept: "application/pdf" }}
                placeholder="Select resume file"
                onChange={this.handleAddResumeFileUpload}
                data-test-id="editResumeFileInput"
              />
              <label htmlFor="updateResumeFile" style={webStyle.candidateManagementEditLabel}>
                Upload resume file ("pdf format only")
              </label>
            </Box>
            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                style={webStyle.modalTextInput}
                placeholder="Enter your resume summary"
                value={this.state.newResumeSummaryWeb}
                onChange={this.handleSummaryResumeInputChangeText}
                data-test-id="editResumeSummaryInput"
              />
            </Box>
            <Box sx={webStyle.checkboxWithNoteContainer}>
              <Checkbox
                onClick={this.toggleCheckbox}
                checked={Boolean(this.state.allow_publish_web)}
                value={this.state.allow_publish_web}
                data-test-id="editResumeCheckboxInput"
              />
              <Typography style={webStyle.checkBoxNote}>
                Do you want to show this resume to all ?
              </Typography>
            </Box>
            <Box sx={webStyle.modelButtonsContainer}>
              <Button
                style={webStyle.modalBtnCancel}
                onClick={() => this.handleEditResumeModalOpen()}
                data-test-id="editResumeCancelBtn"
              >
                Cancel
              </Button>
              <Button
                style={webStyle.modalBtnSubmit}
                onClick={this.handleSubmitResumeWeb}
                data-test-id="editResumeSubmitBtn"
              >
                Submit
              </Button>
            </Box>
            <Box sx={webStyle.showAlertMessage}>{this.state.showAlertMsg}</Box>
          </Box>
        </Container>
      </Modal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderAddResumeModal = () => {
    return (
      <Modal
        open={this.state.isAddResumeModalOpenWeb}
        style={webStyle.modal}
      >
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.modalContainer}>
            <Typography variant="h5" style={webStyle.modalHeading}>
              Add New Resume
            </Typography>
            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                placeholder="Enter the title for this resume"
                style={webStyle.modalTextInput}
                value={this.state.titleWeb}
                data-test-id="addNewResumeTitleInput"
                onChange={this.handleTitleResumeInputChangeText}
              />
            </Box>
            <Box style={webStyle.modalTextInputFileBox}>
              <Input
                type="file"
                id="addNewResumeFileLabel"
                data-test-id="addNewResumeFileInput"
                inputProps={{ accept: "application/pdf" }}
                placeholder="Select resume file"
                style={webStyle.modalTextInputFile}
                onChange={this.handleAddResumeFileUpload}
              />
              <label htmlFor="addNewResumeFileLabel" style={webStyle.candidateManagementLabel}>
                Select resume file ("pdf format only")
              </label>
            </Box>
            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                data-test-id="addNewResumeSummaryInput"
                placeholder="Enter your resume summary"
                style={webStyle.modalTextInput}
                value={this.state.newResumeSummaryWeb}
                onChange={this.handleSummaryResumeInputChangeText}
              />
            </Box>
            <Box sx={webStyle.checkboxWithNoteContainer}>
              <Checkbox
                onClick={this.toggleCheckbox}
                data-test-id="addNewResumeCheckbox"
              />
              <Typography style={webStyle.checkBoxNote}>
                Do you want to show this resume to all ?
              </Typography>
            </Box>
            <Box sx={webStyle.modelButtonsContainer}>
              <Button
                style={webStyle.modalBtnCancel}
                onClick={this.handleAddResumeModalOpen}
              >
                Cancel
              </Button>
              <Button
                style={webStyle.modalBtnSubmit}
                onClick={this.handleSubmitResumeWeb}
                data-test-id="addNewResumeSubmitBtn"
              >
                Submit
              </Button>
            </Box>
            <Box sx={webStyle.showAlertMessage}>{this.state.showAlertMsg}</Box>
          </Box>
        </Container>
      </Modal>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderProfileShowModal = () => {
    return (
      <Modal open={this.state.isProfileShownWeb}>
        <Container maxWidth="sm">
          <Box sx={webStyle.darkBgModal}>
            <Typography style={webStyle.darkBgHeading} variant="h3">
              Profile Details
            </Typography>
            <Box sx={webStyle.profileDetailsContainer}>
              <Box sx={webStyle.profileDetailsContainerRow}>
                <Box sx={webStyle.rowHeading}>Person Name:</Box>
                <Box sx={webStyle.rowInfo}>
                  {this.state.profileDataWeb.name}
                </Box>
              </Box>
              <Box sx={webStyle.profileDetailsContainerRow}>
                <Box sx={webStyle.rowHeading}>Age:</Box>
                <Box sx={webStyle.rowInfo}>
                  {this.state.profileDataWeb.age}
                </Box>
              </Box>
              <Box sx={webStyle.profileDetailsContainerRow}>
                <Box sx={webStyle.rowHeading}>Years of Experience:</Box>
                <Box sx={webStyle.rowInfo}>
                  {this.state.profileDataWeb.years_of_experience}
                </Box>
              </Box>
              <Box sx={webStyle.profileDetailsContainerRow}>
                <Box sx={webStyle.rowHeading}>Experience Summary:</Box>
                <Box sx={webStyle.rowInfo}>
                  {this.state.profileDataWeb.experience_summary}
                </Box>
              </Box>
              <Box sx={webStyle.profileDetailsContainerRow}>
                <Box sx={webStyle.rowHeading}>Education Summary:</Box>
                <Box sx={webStyle.rowInfo}>
                  {this.state.profileDataWeb.education_summary}
                </Box>
              </Box>
              <Box sx={webStyle.profileDetailsContainerRow}>
                <Box sx={webStyle.rowHeading}>Education File:</Box>
                <Link
                  style={webStyle.rowInfo}
                  target="_blank"
                  href={
                    this.state.profileDataWeb.education_file?.toString() &&
                    this.state.profileDataWeb.education_file.toString()
                  }
                  data-test-id="downloadEduFile"
                >
                  <RemoveRedEye fontSize="medium" />
                </Link>
              </Box>
              <Box sx={webStyle.profileDetailsContainerRow}>
                <Box sx={webStyle.rowHeading}>Experience File:</Box>
                <Link
                  style={webStyle.rowInfo}
                  target="_blank"
                  href={
                    this.state.profileDataWeb.experience_file?.toString() &&
                    this.state.profileDataWeb.experience_file.toString()
                  }
                  data-test-id="downloadExpFile"
                >
                  <RemoveRedEye fontSize="medium" />
                </Link>
              </Box>
              <Box sx={webStyle.profileDetailsButtonsContainer}>
                <Button
                  style={webStyle.profileDetailsBtn}
                  onClick={this.toggleMainProfile}
                  data-test-id="goBackBtn"
                >
                  Go Back
                </Button>
                <Button
                  style={webStyle.profileDetailsBtn}
                  onClick={this.toggleEditProfile}
                  data-test-id="editProfilesBtn"
                >
                  Edit Profile
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Modal>
    )
  }
  // Customizable Area End


  // Customizable Area Start
  renderEditProfileShowModal = () => {
    return (
      <Modal
        open={this.state.isEditProfileWeb}
        style={webStyle.editProfileModal}
      >
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.lightBgModal}>
            <Box sx={webStyle.lightBgHeadingContainer}>
              <Typography variant="h5">Update Profile</Typography>
            </Box>
            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                placeholder="Enter your profile name"
                style={webStyle.modalTextInput}
                value={this.state.profileDataWeb.name}
                data-test-id="updateProfileNameInput"
                onChange={this.handleUpdateProfileName}
              />
            </Box>

            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                placeholder="Enter your age"
                style={webStyle.modalTextInput}
                value={this.state.profileDataWeb.age}
                data-test-id="updateProfileAgeInput"
                onChange={this.handleUpdateProfileAge}
              />
            </Box>

            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                placeholder="Enter your years of experience"
                style={webStyle.modalTextInput}
                value={this.state.profileDataWeb.years_of_experience}
                data-test-id="updateProfileYearsOfExpInput"
                onChange={this.handleUpdateProfileYearsOfExperience}
              />
            </Box>

            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                placeholder="Enter your profile experience summary"
                style={webStyle.modalTextInput}
                value={this.state.profileDataWeb.experience_summary}
                data-test-id="updateProfileExpSummaryInput"
                onChange={this.handleUpdateProfileExperienceSummary}
              />
            </Box>

            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                placeholder="Enter your profile education summary"
                style={webStyle.modalTextInput}
                value={this.state.profileDataWeb.education_summary}
                data-test-id="updateProfileEduSummaryInput"
                onChange={this.handleUpdateProfileEducationSummary}
              />
            </Box>

            <Box style={webStyle.modalTextInputFileBox}>
              <Input
                type="file"
                id="updateProfileEducationLabel"
                inputProps={{ accept: "application/pdf" }}
                placeholder="Select education file"
                style={webStyle.modalTextInputFile}
                data-test-id="updateProfileEduFileInput"
                onChange={this.handleEducationFile}
              />
              <label htmlFor="updateProfileEducationLabel" style={webStyle.candidateManagementLabel}>Upload education file</label>
            </Box>
            <Box style={webStyle.modalTextInputFileBox}>
              <Input
                type="file"
                id="updateProfileExperienceLabel"
                inputProps={{ accept: "application/pdf" }}
                placeholder="Select experience"
                style={webStyle.modalTextInputFile}
                onChange={this.handleExperienceFile}
                data-test-id="updateProfileExpFileInput"
              />
              <label htmlFor="updateProfileExperienceLabel" style={webStyle.candidateManagementLabel}>Upload experience file</label>
            </Box>
            <Box sx={webStyle.showAlertMessage}>{this.state.showAlertMsg}</Box>
            <Box sx={webStyle.twoBtnContainer}>
              <Button
                style={webStyle.updateProfileSubmitBtn}
                onClick={this.toggleEditProfile}
                data-test-id="updateProfileCancelBtn"
              >
                Cancel
              </Button>
              <Button
                style={webStyle.updateProfileSubmitBtn}
                onClick={this.handleSubmitProfile}
                data-test-id="updateProfileUpdateBtn"
              >
                Update
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>
    )
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"}>
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.headerContainer}>
              <img
                src={imgProfile}
                alt="profile"
                style={webStyle.profileImg}
                data-test-id="profileImg"
                onClick={this.toggleMainProfile}
              />
              <Typography style={webStyle.mainHeading}>Dashboard</Typography>
            </Box>
            <Box style={webStyle.searchAndButtonsContainer}>
              <Box sx={webStyle.searchContainer}>
                <Box sx={webStyle.searchIconBox}>
                  <Search fontSize="large" />
                </Box>
                <Input
                  type="search"
                  style={webStyle.searchInput}
                  placeholder="Search"
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                  onChange={this.searchByTextWeb}
                  data-test-id="txtInput"
                />
                <Accordion
                  expanded={this.state.isSelectTypeOpen}
                  onClick={this.toggleIsSelectTypeOpen}
                  style={webStyle.typeDropdownContainer}
                >
                  <AccordionSummary
                    data-test-id="dropdownBtn"
                    expandIcon={<Tune fontSize="large" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  />

                  <AccordionDetails style={webStyle.accordionDetails}>
                    <Box sx={webStyle.typeDropdownBtnsContainer}>
                      <Button
                        data-test-id="dropdownAllBtn"
                        onClick={() => this.handleFilterResumePublishType("")}
                      >
                        All
                      </Button>
                      <Button
                        data-test-id="dropdownPublicBtn"
                        onClick={() => this.handleFilterResumePublishType("true")}
                      >
                        Public
                      </Button>
                      <Button
                        data-test-id="dropdownPrivateBtn"
                        onClick={() => this.handleFilterResumePublishType("false")}
                      >
                        Private
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box>
                <Box sx={webStyle.addResumeBtnBox1}>
                  <Button
                    style={webStyle.addResumeBtn}
                    data-test-id="addNewResumeBtn"
                    onClick={this.handleAddResumeModalOpen}
                  >
                    <Typography style={webStyle.templateBtnText}>
                      Add New Resume
                    </Typography>
                  </Button>
                  <Button
                    style={webStyle.addResumeBtn}
                    data-test-id="goToTemplatePageBtn"
                    component="section"
                    onClick={this.goToTemplateScreen}
                  >
                    <Typography style={webStyle.templateBtnText}>
                      Go To Template
                    </Typography>
                  </Button>
                </Box>
                <Button
                  style={webStyle.publicResumeLargeBtn}
                  data-test-id="goToPublicResumesPageBtn"
                  onClick={this.goToPublicResumeWeb}
                >
                  <Typography style={webStyle.templateBtnText}>
                    Publically Available Resumes
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Typography style={webStyle.headingMyResumes} variant="h6">
              My Resumes
            </Typography>
            {this.renderAllResume()}
            {this.renderEditResumeModal()}
            {this.renderAddResumeModal()}
            {this.renderProfileShowModal()}
            {this.renderEditProfileShowModal()}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default CvresumeCandidateManagement2;
// Customizable Area End

// Customizable Area Start
const webStyle = {
  accordionDetails: {
    padding: "0 6px 0",
  },
  typeDropdownBtnsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "2px",
  },
  typeDropdownContainer: {
    backgroundColor: "grey",
    padding: "5px",
    width: "90px",
    boxShadow: "none",
    zIndex: 99999,
    marginRight: "5px"
  },
  showAlertMessage: {
    width: "100%",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "tomato",
  },
  rowInfo: {
    width: "50%",
    color: "white",
    fontWeight: 700,
  },
  rowHeading: {
    width: "50%",
    color: "white",
    fontWeight: "bold",
  },
  editProfileModal: {
    position: "absolute" as "absolute",
    overflow: "scroll",
    display: "block",
  },
  updateProfileSubmitBtn: {
    backgroundColor: "#800000",
    height: "50px",
    width: "120px",
    color: "white",
    borderRadius: "12px",
  },
  profileDetailsBtn: {
    width: "160px",
    height: "60px",
    color: "white",
    backgroundColor: "grey",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  profileDetailsButtonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "15px",
    margin: "10px 0",
  },
  profileDetailsContainerRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    marginBottom: "10px",
  },
  profileDetailsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "15px",
  },
  lightBgHeadingContainer: {
    width: "100%",
    textAlign: "center",
    margin: "10px 0",
  },
  lightBgModal: {
    bgcolor: "white",
    padding: "10px",
  },
  darkBgModal: {
    height: "100%",
    bgcolor: "black",
    padding: "10px",
  },
  darkBgHeading: {
    color: "white",
    fontWeight: 500,
    fontSize: "22px",
    margin: "0px auto",
    width: "max-content",
  },
  twoBtnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "10px 0",
  },
  modalBtnCancel: {
    height: "50px",
    width: "120px",
    backgroundColor: "#260041",
    color: "white",
    fontWeight: 600,
    borderRadius: "12px",
  },
  modalBtnSubmit: {
    height: "50px",
    width: "120px",
    backgroundColor: "003300",
    color: "white",
    fontWeight: 600,
    borderRadius: "12px",
  },
  checkBoxNote: {
    fontSize: "20px",
    fontWeight: 500,
  },
  checkboxWithNoteContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "20px 0",
  },
  modelButtonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    margin: "30px 0",
  },
  modalTextInputBox: {
    height: "80px",
    border: ".5px solid lightgrey",
    backgroundColor: "grey",
    borderRadius: "12px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalTextInputFileBox: {
    height: "80px",
    border: ".5px solid lightgrey",
    backgroundColor: "grey",
    borderRadius: "12px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingLeft: "58px",
    paddingRight: "10px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    fontSize: "16px"
  },
  modalTextInput: {
    height: "80px",
    width: "80%",
    color: "white",
  },
  modalTextInputFile: {
    display: "none",
    cursor: "pointer"
  },
  modalHeading: {
    margin: "5px auto",
    width: "220px",
  },
  modalContainer: {
    height: "100%",
    backgroundColor: "white",
    padding: "10px",
  },
  modal: {
    backgroundColor: "white",
  },
  listBoxItemButtons: {
    display: "flex",
    justifyContent: "space-between",
    height: "90px",
    alignItems: "center",
    padding: "0 20px",
    color: "white",
  },
  listBoxPublishTypeItem: {
    width: "192spx",
    fontSize: "19px",
    fontWeight: "bold",
  },
  listBoxNameAndSummaryItem: {
    width: "162px",
    fontSize: "19px",
    fontWeight: "bold",
  },
  listBoxItemData: {
    width: "100%",
    textAlign: "center",
    textTransform: "capitalize",
    fontSize: "20px",
    padding: "2px 0",
  },
  listBoxItem: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    padding: "6px 0",
  },
  listContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "20px 0",
  },
  listItemsContainer: {
    border: "3px solid white",
    borderRadius: "12px",
    padding: "15px",
    width: "520px",
    minHeight: "330px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "10px",
  },
  addResumeBtnBox1: {
    display: "flex",
    justifyContent: "space-between",
  },
  eyeBtn: {
    height: "55px",
    width: "80px",
    backgroundColor: "#0000cc",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
  },
  deleteBtn: {
    height: "55px",
    width: "80px",
    backgroundColor: "tomato",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
  },
  editBtn: {
    height: "55px",
    width: "80px",
    backgroundColor: "006600",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    color: "#fff",
    fontSize: "22px",
    flexDirection: "column"
  },
  mainHeading: {
    color: "#fff",
    fontSize: "29px",
    margin: "12px auto",
  },
  searchContainer: {
    height: "70px",
    width: "100%",
    margin: "20px auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.5px solid grey",
    borderRadius: "10px",
    backgroundColor: "grey",
  },
  searchIconBox: {
    height: "100%",
    width: "80px",
    color: "darkgrey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchInput: {
    color: "white",
    height: "100%",
  },
  addResumeBtn: {
    cursor: "pointer",
    height: "60px",
    width: "40%",
    backgroundColor: "green",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
  },
  publicResumeLargeBtn: {
    cursor: "pointer",
    height: "60px",
    width: "100%",
    backgroundColor: "green",
    marginTop: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
  },
  templateBtnText: {
    color: "#fff",
    fontWeight: 400,
  },
  headingMyResumes: {
    color: "white",
    fontSize: "22px",
    width: "100%",
    textAlign: "center" as "center",
    textDecoration: "underline",
  },
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    minHeight: "100vh",
    background: "black",
  },
  profileImg: {
    border: "0.5px solid black",
    borderRadius: "50%",
    height: "80px",
    width: "80px",
    cursor: "pointer",
  },
  candidateManagementLabel: {
    cursor: "pointer",
    color: "#e1dfdf94",
  },
  candidateManagementEditLabel: {
    cursor: "pointer",
    color: "white",
  },
  searchAndButtonsContainer: {
    padding: "10px"
  }
};
// Customizable Area End
