import React from "react";
// Customizable Area Start
import { Col, Row, Form, Input, Button, Select } from "antd";
import DataEncryptionController from "./DataEncryptionController.web";
// Customizable Area End

export default class DataEncryption extends DataEncryptionController {
  // Customizable Area Start
  renderDataEncryptionHeader = () => {
    return (
      <>
        <div style={webStyle.header}>
          <div
            data-test-id="showStringDataID"
            className={
              this.state.isShowStrData ? "active_data_encrypt" : "data_encrypt"
            }
            style={
              this.state.isShowStrData
                ? webStyle.activeEncryption
                : webStyle.inactiveEncryption
            }
            onClick={this.handleStrData}>
            {"String"}
          </div>
          <div
            data-test-id="showFileDataID"
            className={
              this.state.isShowFileData ? "active_data_encrypt" : "data_encrypt"
            }
            style={
              this.state.isShowFileData
                ? webStyle.activeEncryption
                : webStyle.inactiveEncryption
            }
            onClick={this.handleFileData}>
            File
          </div>
          <div
            data-test-id="showFolderDataID"
            className={
              this.state.isShowFolderData
                ? "active_data_encrypt"
                : "data_encrypt"
            }
            style={
              this.state.isShowFolderData
                ? webStyle.activeEncryption
                : webStyle.inactiveEncryption
            }
            onClick={this.handleFolderData}>
            Folder
          </div>
        </div>
        <div style={webStyle.container}>
          {this.state.isShowStrData && this.renderDataEncryption("String")}
          {this.state.isShowFileData && this.renderDataEncryption("File")}
          {this.state.isShowFolderData && this.renderDataEncryption("Folder")}
        </div>
      </>
    );
  };

  renderDataEncryption = (selectedType: string) => {
    const decryptionList = this.state.decryptionList;
    const encryptionList = this.state.encryptionList;
    const onFinishDecryption = this.getOnFinishDecryptionFunction(selectedType);
    return (
      <Row justify="center" style={webStyle.mainBox} key={selectedType}>
        <Col span={20}>
          <div style={webStyle.headingBox}>
            <div style={webStyle.headingContainer}>
              <p style={webStyle.heading}>{`${selectedType} Encryption`}</p>
            </div>
            <Form
              data-dest-id="handlePostSelectEncryptedStringData"
              onFinish={this.handlePostSelectEncryptedStrData}>
              <Row gutter={[12, 12]} justify="center">
                <Col span={16}>
                  <Form.Item
                    name={selectedType}
                    className="string_field select_field_box"
                    rules={[
                      {
                        required: true,
                        message: `${selectedType} is required`,
                      },
                    ]}>
                    <Select
                      data-test-id="setSelectEncryptDataID"
                      className="select_field_input"
                      allowClear
                      placeholder={`Encrypted ${selectedType.toLowerCase()} history`}
                      onChange={(event) =>
                        this.getOnChangeFunction(event, selectedType)
                      }>
                      {encryptionList.map((item, listNumber) => {
                        return (
                          <Select.Option
                            value={
                              selectedType === "String"
                                ? item.name
                                : item.encrypted_value
                            }
                            key={listNumber}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>{this.getBtnEncryptionElement(selectedType)}</Col>
              </Row>
            </Form>
            <div className="string_field_box">
              <Form
                data-dest-id="handleStringEncryptionOnfinish"
                onFinish={this.handleStrEncryptionOnfinish.bind(
                  this,
                  selectedType,
                )}>
                <Row gutter={[12, 12]} justify="center">
                  <Col span={17}>
                    <Form.Item
                      name={selectedType}
                      label={selectedType}
                      className="string_field">
                      {this.getInputElement(selectedType)}
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div className="string_encrypt_button_wrapper">
                      <Button
                        data-test-id={this.getEncryptionDataTestId(
                          selectedType,
                        )}
                        htmlType="submit"
                        className="string_encrypt_button"
                        style={webStyle.encryptButton}>
                        {"Encrypt"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              <Row
                gutter={[5, 5]}
                justify="center"
                className="encrypted_data_box">
                <Col span={17} className="encrypted_inner_box">
                  {this.showEncryptedElement(selectedType)}
                </Col>
                <Col span={3}>
                  {this.getBtnDownloadEncryption(selectedType)}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={20}>
          <div style={webStyle.headingBox}>
            <div style={webStyle.headingContainer}>
              <p style={webStyle.heading}>{`${selectedType} Decryption`}</p>
            </div>
            <Form
              data-dest-id="handlePostSelectDecryptedStringData"
              onFinish={this.handlePostSelectDecryptedStrData}>
              <Row gutter={[12, 12]} justify="center">
                <Col span={16}>
                  <Form.Item
                    name={selectedType}
                    className="string_field select_field_box"
                    rules={[
                      {
                        required: true,
                        message: `${selectedType} is required`,
                      },
                    ]}>
                    <Select
                      data-test-id="setSelectDecryptDataID"
                      className="select_field_input"
                      allowClear
                      placeholder={`Decrypted ${selectedType.toLowerCase()} history`}
                      onChange={(event) =>
                        this.getOnChangeDecryption(event, selectedType)
                      }>
                      {decryptionList.map((item, listNumber) => {
                        return (
                          <Select.Option
                            value={
                              selectedType === "String"
                                ? item.name
                                : item.decrypted_value
                            }
                            key={listNumber}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>{this.getBtnDecryptionElement(selectedType)}</Col>
              </Row>
            </Form>

            <div className="string_field_box">
              <Form onFinish={onFinishDecryption}>
                <Row gutter={[12, 12]} justify="center">
                  <Col span={17}>
                    <Form.Item
                      name={selectedType}
                      label={selectedType}
                      className="string_field">
                      {this.getInputDecryptionElement(selectedType)}
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <div className="string_encrypt_button_wrapper">
                      <Button
                        htmlType="submit"
                        className="string_decrypt_button"
                        style={webStyle.encryptButton}
                        data-test-id={this.getDecryptionDataTestId(
                          selectedType,
                        )}>
                        {"Decrypt"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              <Row
                gutter={[5, 5]}
                justify="center"
                className="encrypted_data_box">
                <Col span={17} className="encrypted_inner_box">
                  {this.showDecryptedElement(selectedType)}
                </Col>
                <Col span={3}>
                  {this.getBtnDownloadDecryption(selectedType)}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  getInputElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "String" ? (
          <Input
            type="text"
            data-test-id="setInputStringDataID"
            className="string_field_input"
            name={selectedType}
            value={this.state.strEncryptData}
            placeholder={`Please enter ${selectedType.toLowerCase()}`}
            onChange={this.setStrEncryptData}
          />
        ) : (
          <Input
            type="file"
            data-test-id={
              selectedType === "File"
                ? "setInputFileDataID"
                : "setInputFolderDataID"
            }
            className="string_field_input"
            name={selectedType}
            placeholder={`Please enter ${selectedType.toLowerCase()}`}
            onChange={
              selectedType === "File"
                ? this.setFileEncryptData
                : this.setFolderEncryptData
            }
          />
        )}
      </>
    );
  };

  showEncryptedElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "String" ? (
          <Input
            type="text"
            className="Encrypted_field_input"
            value={this.state.encryptedStr}
            placeholder={`Encrypted ${selectedType.toLowerCase()}`}
          />
        ) : (
          this.getFileFolderInputElement(selectedType)
        )}
      </>
    );
  };

  getFileFolderInputElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "File" ? (
          <Input
            type="text"
            className="Encrypted_field_input"
            value={this.state.encryptedFile}
            placeholder={`Encrypted ${selectedType.toLowerCase()}`}
          />
        ) : (
          <Input
            type="text"
            className="Encrypted_field_input"
            value={this.state.encryptedFolder}
            placeholder={`Encrypted ${selectedType.toLowerCase()}`}
          />
        )}
      </>
    );
  };

  getBtnEncryptionElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "String" ? (
          <div className="string_encrypt_button_wrapper">
            <Button
              data-test-id="selectStringEncryptionButtonDataID"
              htmlType="submit"
              className="string_decrypt_button"
              style={webStyle.encryptButton}>
              {"Encrypt"}
            </Button>
          </div>
        ) : (
          this.encryptionFileFolderElement(selectedType)
        )}
      </>
    );
  };

  encryptionFileFolderElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "File" ? (
          <div
            onClick={this.handleSelectEncryptFileDownload}
            data-test-id="selectFileEncryptionButtonDataID"
            className="Encrypted_download_button select_button"
            style={webStyle.downloadButton}>
            {"Download"}
          </div>
        ) : (
          <div
            onClick={this.handleSelectEncryptFolderDownload}
            data-test-id="selectFolderEncryptionButtonDataID"
            className="Encrypted_download_button select_button"
            style={webStyle.downloadButton}>
            {"Download"}
          </div>
        )}
      </>
    );
  };

  getBtnDownloadEncryption = (selectedType: string) => {
    return (
      <>
        {selectedType === "File" ? (
          <div
            onClick={this.handleFileDownload}
            data-test-id="fileEncryptedDownloadButtonDataID"
            className="Encrypted_download_button"
            style={webStyle.downloadButton}>
            {"Download"}
          </div>
        ) : (
          selectedType === "Folder" && (
            <div
              onClick={this.handleFolderDownload}
              data-test-id="folderEncryptedDownloadButtonDataID"
              className="Encrypted_download_button"
              style={webStyle.downloadButton}>
              {"Download"}
            </div>
          )
        )}
      </>
    );
  };

  getBtnDecryptionElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "String" ? (
          <div className="string_encrypt_button_wrapper">
            <Button
              data-test-id="selectStringDecryptionButtonDataID"
              htmlType="submit"
              className="string_decrypt_button"
              style={webStyle.encryptButton}>
              {"Decrypt"}
            </Button>
          </div>
        ) : (
          this.decryptionFileFolderElement(selectedType)
        )}
      </>
    );
  };

  decryptionFileFolderElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "File" ? (
          <div
            data-test-id="selectFileDecryptionButtonDataID"
            onClick={this.handleSelectFileDownload}
            className="Encrypted_download_button select_button"
            style={webStyle.downloadButton}>
            {"Download"}
          </div>
        ) : (
          <div
            data-test-id="selectFolderDecryptionButtonDataID"
            onClick={this.handleSelectFolderDownload}
            className="Encrypted_download_button select_button"
            style={webStyle.downloadButton}>
            {"Download"}
          </div>
        )}
      </>
    );
  };

  getBtnDownloadDecryption = (selectedType: string) => {
    return (
      <>
        {selectedType === "File" ? (
          <div
            onClick={this.handleDecryptedFileDownload}
            data-test-id="fileDecryptedDownloadButtonDataID"
            className="Encrypted_download_button"
            style={webStyle.downloadButton}>
            {"Download"}
          </div>
        ) : (
          selectedType === "Folder" && (
            <div
              onClick={this.handleDecryptedFolderDownload}
              data-test-id="folderDecryptedDownloadButtonDataID"
              className="Encrypted_download_button"
              style={webStyle.downloadButton}>
              {"Download"}
            </div>
          )
        )}
      </>
    );
  };

  getInputDecryptionElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "String" ? (
          <Input
            type="text"
            data-test-id="setStringDecryptDataID"
            className="string_field_input"
            name={selectedType}
            value={this.state.strDecryptData}
            placeholder={`Please enter ${selectedType.toLowerCase()}`}
            onChange={this.setStrDecryptData}
          />
        ) : (
          <Input
            type="file"
            data-test-id={
              selectedType === "File"
                ? "setFileDecryptDataID"
                : "setFolderDecryptDataID"
            }
            className="string_field_input"
            name={selectedType}
            placeholder={`Please enter ${selectedType.toLowerCase()}`}
            onChange={
              selectedType === "File"
                ? this.setFileDecryptData
                : this.setFolderDecryptData
            }
          />
        )}
      </>
    );
  };

  showDecryptedElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "String" ? (
          <Input
            type="text"
            className="Encrypted_field_input"
            value={this.state.decryptedStr}
            placeholder={`Encrypted ${selectedType.toLowerCase()}`}
          />
        ) : (
          this.showDecryptedFileFolderElement(selectedType)
        )}
      </>
    );
  };

  showDecryptedFileFolderElement = (selectedType: string) => {
    return (
      <>
        {selectedType === "File" ? (
          <Input
            type="text"
            className="Encrypted_field_input"
            value={this.state.decryptedFile}
            placeholder={`Encrypted ${selectedType.toLowerCase()}`}
          />
        ) : (
          <Input
            type="text"
            className="Encrypted_field_input"
            value={this.state.decryptedFolder}
            placeholder={`Encrypted ${selectedType.toLowerCase()}`}
          />
        )}
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <div className="Main_container">
          {this.renderDataEncryptionHeader()}
        </div>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  header: {
    display: "flex",
    justifyContent: "space-around",
    background: "#2c91998a",
    fontSize: "18px",
    fontWeight: 600,
    padding: "20px",
  },
  activeEncryption: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#6600FF",
    letterSpacing: "1px",
    cursor: "pointer",
  },
  inactiveEncryption: {
    fontSize: "18px",
    fontWeight: 600,
    color: "rgb(229 224 220)",
    letterSpacing: "1px",
    cursor: "pointer",
  },
  container: {
    margin: "50px 0",
  },
  mainBox: {
    margin: "0 !important",
  },
  headingBox: {
    width: "100%",
    textAlign: "center" as "center",
  },
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    letterSpacing: "0.5px",
    color: "#3e9198",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  encryptButton: {
    backgroundColor: "#2c91998a",
    color: "rgb(229 224 220)",
    borderRadius: "6px",
    fontSize: "20px",
    fontWeight: 400,
    letterSpacing: "0.5px",
    display: "flex",
    alignItems: "center",
  },
  downloadButton: {
    color: "#2c91998a !important",
    fontSize: "20px",
    height: "40px",
    fontWeight: 600,
    letterSpacing: "0.5px",
    textDecoration: "underline",
    cursor: "pointer",
  },
};
// Customizable Area End
